<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow q-pa-md">
      <div class="column q-col-gutter-sm">
        <q-input outlined label="Username" v-model="data.username"></q-input>
      </div>
    </q-scroll-area>
    <q-btn
      class="no-border-radius"
      color="primary"
      label="register"
      @click="addSales(data.username)"
    ></q-btn>
  </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from "vue";
import useSales from "./useSales";
export default {
  setup() {
    const { addSales, data } = useSales();

    onMounted(() => {});

    return {
      addSales,
      data,
    };
  },
};
</script>
